import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import Spinner from "@/shared/ui/Spinner/Spinner";
import WebOnboardingHomePage from "@/widgets/WebOnboardingHomePage/ui/PhoneHomePage/WebOnboardingHomePage";
import { getFilterAppsState } from "@/entities/filterApps";
import { getFilterInputSearchState } from "@/entities/filterInputSearch";
import { activeModalIndexActions } from "@/entities/activeModalIndex";
import { useGetAppOnboardingVideosQuery } from "@/features/auth/api/authAPI";
import { ACTIVE_VIEW_WEB_ONBOARDING } from "@/Views/constants";
import styles from "./WebOnboarding.module.scss";
import { getFilterState } from "@/entities/filter";

const makeOrderValue = (key: string, direction: string) => {
  if (key !== "") {
    return `${direction === "desc" ? "-" : ""}${key}`;
  } else {
    return "";
  }
};

const WebOnboarding = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [filterParams, setFilterParams] = useState({
    order: "-revenue",
  });
  const [allVideos, setAllVideos] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const { appName } = useSelector(getFilterInputSearchState);
  const { isActiveMagic } = useSelector(getFilterState);
  const { setRootActiveView } = useOutletContext();

  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0,
    rootMargin: "100px",
  });

  const {
    filterSort,
    categories,
    paywall,
    oscgte,
    osclte,
    revenuegte,
    revenuelte,
    downloadgte,
    downloadlte,
    revInstallRateGte,
    revInstallRateLte,
    releasedFrom,
    releasedTo,
    updatedFrom,
    updatedTo,
    sortDirection,
    flowType,
  } = useSelector(getFilterAppsState);

  // Update filter parameters when any filter changes
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      return;
    }

    const downloadgteNumber = downloadgte === "Min" ? "" : downloadgte;
    const downloadlteNumber = downloadlte === "Max" ? "" : downloadlte;
    const revenuegteNumber = revenuegte === "Min" ? "" : revenuegte;
    const revenuelteNumber = revenuelte === "Max" ? "" : revenuelte;
    const oscgteNumber = oscgte === "Min" ? "" : oscgte;
    const osclteNumber = osclte === "Max" ? "" : osclte;
    const revInstallRateGteNumber =
      revInstallRateGte === "Min" ? "" : revInstallRateGte;
    const revInstallRateLteNumber =
      revInstallRateLte === "Max" ? "" : revInstallRateLte;

    setAllVideos([]);
    setPage(1);
    setIsLoadingMore(false);

    setFilterParams({
      name: appName,
      categories,
      downloadgteNumber,
      downloadlteNumber,
      revenuegteNumber,
      revenuelteNumber,
      paywall,
      oscgteNumber,
      osclteNumber,
      releasedFrom,
      releasedTo,
      updatedFrom,
      updatedTo,
      order: makeOrderValue(filterSort, sortDirection),
      flow_type: flowType,
      revInstallRateGte: revInstallRateGteNumber,
      revInstallRateLte: revInstallRateLteNumber,
    });
  }, [
    categories,
    appName,
    downloadgte,
    downloadlte,
    revenuegte,
    revenuelte,
    revInstallRateGte,
    revInstallRateLte,
    paywall,
    oscgte,
    osclte,
    releasedFrom,
    releasedTo,
    updatedFrom,
    updatedTo,
    filterSort,
    sortDirection,
    flowType,
  ]);

  useEffect(() => {
    setRootActiveView(ACTIVE_VIEW_WEB_ONBOARDING);
  }, []);

  useEffect(() => {
    dispatch(activeModalIndexActions.setActiveModalIndexFilterItem(1));
  }, []);

  const { data, isLoading, isFetching } = useGetAppOnboardingVideosQuery({
    page,
    page_size: 4,
    ...filterParams,
  });

  // Handle infinite scroll
  useEffect(() => {
    if (inView && !isLoading && !isFetching && !isLoadingMore) {
      setIsLoadingMore(true);
      setPage((prev) => prev + 1);
    }
  }, [inView, isLoading, isFetching, isLoadingMore]);

  // Handle data updates
  useEffect(() => {
    if (!data?.results || isLoading) return;

    if (page === 1) {
      setAllVideos(data.results);
    } else if (isLoadingMore) {
      setAllVideos((prev) => [...prev, ...data.results]);
      setIsLoadingMore(false);
    }
  }, [data, isLoading]);

  const hasMore = !!data?.next;

  return (
    <div
      className={`${styles.container} ${isActiveMagic ? styles.overlay : ""}`}
    >
      <div className={`${styles.cards} ${isLoading ? styles.loading : ""}`}>
        {allVideos.map((item, index) => (
          <WebOnboardingHomePage
            key={`${item.id}-${index}`}
            videoData={item}
            isOpen={false}
          />
        ))}
      </div>
      {hasMore && (
        <div ref={loadMoreRef} className={styles.loadingContainer}>
          {(isLoading || isFetching) && <Spinner />}
        </div>
      )}
    </div>
  );
};

export default WebOnboarding;
