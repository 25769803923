import React, { ChangeEvent, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import FilterModalItem from "@/widgets/filterModal/ui/FilterModalItem/FilterModalItem";
import CategoryModal from "@/widgets/categoryModal/ui/CategoryModal/CategoryModal";
import { ICategoriesData } from "@/widgets/categoryModal/libs/types/ICategoriesData";
import ReleaseDateModal, {
  ReleaseDateModalProps,
} from "@/widgets/releaseDateModal/ui/ReleaseDateModal/ReleaseDateModal";
import UpdateDateModal, {
  UpdateDateModalProps,
} from "@/widgets/updateDateModal/ui/UpdateDateModal/UpdateDateModal";
import {
  AppPaywall,
  AppResponseData,
  IAppData,
  ResponseAvsTags,
} from "@/features/auth/types/auth";
import { sortByCategoriesActions } from "@/entities/sortByCategories";
import { filterActions } from "@/entities/filter";
import { filterAppsActions, getFilterAppsState } from "@/entities/filterApps";
import {
  filterInputSearchActions,
  getFilterInputSearchState,
} from "@/entities/filterInputSearch";
import {
  useGetAppCategoriesQuery,
  useGetAppPaywallQuery,
} from "@/features/auth/api/authAPI";
import {
  filterAppsDataActions,
  getFilterAppsData,
} from "@/entities/filterAppsData";
import { inputSearchActions } from "@/entities/inputSearch";
import { getSortByCategoriesSlice } from "@/entities/sortByCategories/model/selectors/getSortByCategoriesState";
import { modalFiltersActions } from "@/entities/modalFilters/model/slice/modalFiltersSlice";
import { getModalFiltersState } from "@/entities/modalFilters/model/selectors/getModalFiltersState";
import { counterActions } from "@/entities/counter";
import WrapperModal from "@/shared/ui/WrapperModal/WrapperModal";
import { SortByButton } from '@/shared/ui/SortByButton/SortByButton';
import { buttonFilterActions, getButtonFilter } from '@/entities/buttonFilter';

import OnboardingSteps, {
  OnboardingStepsProps,
} from "@/widgets/onboardingSteps/ui/OnboardingSteps/OnboardingSteps";
import PaywallType, {
  PaywallTypeProps,
} from "@/widgets/paywallType/ui/PaywallType/PaywallType";
import ContainsWords, {
  ContainsWordsProps,
} from "@/shared/ui/ContainsWords/ContainsWords";
import MontlyInstalls, {
  MontlyInstallsProps,
} from "@/widgets/montlyInstalls/ui/MontlyInstalls/MontlyInstalls";
import MontlyRevenue, {
  MontlyRevenueProps,
} from "@/widgets/montlyRevenue/ui/MontlyRevenue/MontlyRevenue";
import InputSearch from "@/widgets/inputSearch/ui/InputSearch/InputSearch";
import FilterSortedElements from "@/shared/ui/FilterSortedElements/FilterSortedElements";
import CategoriesDeveloperModal from "@/widgets/categoriesDeveloperModal/ui/CategoriesDeveloperModal/CategoriesDeveloperModal";
import CategoriesRevenueModal from "@/widgets/categoriesRevenueModal/ui/CategoriesRevenueModal/CategoriesRevenueModal";
import { data } from "../../libs/data/data";
import styles from "./FilterModalSidebar.module.scss";
import { ICategoryModalProps } from "@/widgets/categoryModal/libs/types/ICategoryModalProps";
import HiddenModal from "@/widgets/hiddenModal/ui/HiddenModal/HiddenModal";
import FlowsTypes from "@/widgets/FlowsTypes/FlowsTypes";
import debounce from "lodash/debounce";
import { ACTIVE_VIEW_HOME, ACTIVE_VIEW_SEARCH } from '@/Views/constants'
import { RootState } from "@reduxjs/toolkit/query/react";
import RevInstallRate, { RevInstallRateProps } from "@/widgets/RevInstallRate/RevInstallRate";
import { Switch } from '@mui/material';
import { useLocation } from "react-router-dom";


interface FilterModalSidebarProps {
  dataCount?: number;
  isError: boolean;
  refetchFilter?: () => void;
  refetch: () => void;
  isFetching: boolean;
  setSortDirection: (state: string) => void;
  activeType: string;
  setActiveType: (state: string) => void;
  uniqueFilterAppDataSorted: IAppData[];
  isNext: boolean;
  activeButtonHidStar: "hidden" | "starred" | null;
  setActiveButtonHidStar: (state: "hidden" | "starred" | null) => void;
}

interface LabelledModalProps {
  appDataTags: ResponseAvsTags;
  activeType: string;
  setActiveType: (state: string) => void;
}

type FilterHandler =
  | ((value: string) => void)
  | ((value: number) => void)
  | ((max: number, min: number) => void)
  | ((category: string) => void)
  | undefined;

export interface IDataCategories {
  id: number;
  name: string;
}

const FilterModalsidebar: React.FC<FilterModalSidebarProps> = ({
  dataCount,
  isError,
  refetch,
  isFetching: isFetchingFilter,
  setSortDirection,
  uniqueFilterAppDataSorted,
  isNext,
  activeButtonHidStar,
  setActiveButtonHidStar,
}) => {
  const location = useLocation();
  const isRootPath = location.pathname === "/";
  
  const dispatch = useDispatch();
  const { data: appDataCategories } = useGetAppCategoriesQuery();
  const [lastFetchedTime, setLastFetchedTime] = React.useState(Date.now());
  const refFilterModal = React.useRef<HTMLDivElement | null>(null);
  const [, setVisibleModal] = React.useState(false);
  const [dataCategories, setDataCategories] = React.useState<string[]>([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = React.useState<
    number[]
  >([]);
  const [appNameInput, setAppNameInput] = React.useState("");
  const [selectedYearMinRelease, setSelectedYearMinRelease] = React.useState<
    null | number
  >(null);
  const [selectedYearMaxRelease, setSelectedYearMaxRelease] = React.useState<
    null | number
  >(null);
  const [selectedYearMinUpdate, setSelectedYearMinUpdate] = React.useState<
    null | number
  >(null);
  const [selectedYearMaxUpdate, setSelectedYearMaxUpdate] = React.useState<
    null | number
  >(null);
  const [monthMin, setMonthMin] = React.useState(new Date());
  const [monthMax, setMonthMax] = React.useState(new Date());
  const [monthMinUpdate, setMonthMinUpdate] = React.useState(new Date());
  const [monthMaxUpdate, setMonthMaxUpdate] = React.useState(new Date());
  const [selectedFilters, setSelectedFilters] = React.useState({
    appName: "",
    categories: "",
    maxInstalls: 0,
    minInstalls: 0,
    maxRevenue: 0,
    minRevenue: 0,
    revInstallRateGte: 0,
    revInstallRateLte: 0,
    oscgte: 0,
    osclte: 0,
    paywallType: "",
    containsWordFirst: "",
    labelled: "",
    releaseDate: "",
    updateDate: "",
  });
  const [activeButton, setActiveButton] = React.useState<("hidden" | "starred" | "paywall")[] | null>(null);
  const [activeFlowType, setActiveFlowType] = React.useState<string | null>(null);

  // refs
  const inputRef = React.useRef<HTMLDivElement | null>(null);
  const sortByRef = useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView({
    threshold: 0
  });
  // selectors
  const { revenuaOpen, developerOpen } = useSelector(getSortByCategoriesSlice);
  const { filterAppDataSorted, rootActiveView } = useSelector(getFilterAppsData);
  const { indexSort } = useSelector(getSortByCategoriesSlice);
  const {
    isMontlyInstallsOpen,
    isMontlyRevenueOpen,
    isRevInstallRateOpen,
    isCategoryOpen,
    isOnboardingStepsOpen,
    isPaywallOpen,
    isContainsWords,
    isReleaseDateOpen,
    isUpdateDateOpen,
    isHiddenOpen,
    isFlowsOpen,
  } = useSelector(getModalFiltersState);
  const { isActiveGroup, isActiveSort } = useSelector(
    getSortByCategoriesSlice,
  );

  // const { inputIsActive, categoryIsActive } = useSelector(getFilterState);
  const {
    filterGroup,
    categories,
    paywall,
    oscgte,
    osclte,
    revenuegte,
    revenuelte,
    downloadgte,
    downloadlte,
    revInstallRateGte,
    revInstallRateLte,
    selectedDataMax,
    selectedDataMin,
    selectedDataRangeMax,
    selectedDataRangeMin,
    isActiveCategories,
    releasedFrom,
    releasedTo,
    updatedFrom,
    updatedTo,
    isActiveContain,
    isActiveInstalls,
    isActiveOnboarding,
    isActivePaywall,
    isActiveRevenue,
    avsTags,
    only,
    // selectedDataMax,
    // selectedDataMin,
  } = useSelector(getFilterAppsState);


  const { activeView } = useSelector(getButtonFilter)
  const [sortMenuOpen, setSortMenuOpen] = React.useState(false);
  const { appName } = useSelector(getFilterInputSearchState);

  const isPaywallOnly = useSelector((state: RootState) => state.filterApps.isPaywallOnly);

  React.useEffect(() => {
    if (isPaywallOnly && activeButton && !activeButton.includes("paywall")) {
      setActiveButton([...activeButton, "paywall"]);
    } else if (!isPaywallOnly && activeButton?.includes("paywall")) {
      setActiveButton(activeButton.filter(btn => btn !== "paywall"));
    }
  }, [isPaywallOnly]);

  useEffect(() => {
    setAppNameInput(appName)
  }, [appName])


  //console.log('rootActiveView', rootActiveView);


  const downloadgteNumber = downloadgte === "Min" ? "" : downloadgte;
  const downloadlteNumber = downloadlte === "Max" ? "" : downloadlte;
  const revenuegteNumber = revenuegte === "Min" ? "" : revenuegte;
  const revenuelteNumber = revenuelte === "Max" ? "" : revenuelte;
  const oscgteNumber = oscgte === "Min" ? "" : oscgte;
  const osclteNumber = osclte === "Max" ? "" : osclte;
  const revInstallRateLteNumber = revInstallRateLte === "Max" ? "" : revInstallRateLte;
  const revInstallRateGteNumber = revInstallRateGte === "Min" ? "" : revInstallRateGte;

  const isFetching = false


  const { data: appDataPaywall } = useGetAppPaywallQuery();

  const handleDeactivateModal = (e: MouseEvent | React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(filterActions.deactivateFilter());
    dispatch(modalFiltersActions.closeCategoryModal());
    dispatch(modalFiltersActions.closeMontlyInstallsModal());
    dispatch(modalFiltersActions.closeMontlyRevenueModal());
    dispatch(modalFiltersActions.closePaywallModal());
    dispatch(modalFiltersActions.closeOnboardingStepsModal());
    dispatch(modalFiltersActions.closeLabelledModal());
    dispatch(modalFiltersActions.closeRevInstallRateModal());
    dispatch(modalFiltersActions.setIsReleaseDateOpen(false));
    dispatch(modalFiltersActions.setIsUpdateDateOpen(false));
    dispatch(modalFiltersActions.setIsHiddenOpen(false));
    dispatch(inputSearchActions.deactiveOCR());


    // refetchFilter!();
  };

  type SetValueAction = (value: string | number) => {
    type: string;
    payload: string | number;
  };

  const handleMinBlur = (
    currentMinValue: string | number,
    setMinValueAction: SetValueAction,
    onSaveMin: (value: number) => void,
  ) => {
    if (currentMinValue === "") {
      dispatch(setMinValueAction("Min"));
      onSaveMin(0);
    }
  };

  const handleMaxBlur = (
    currentMaxValue: string | number,
    setMaxValueAction: SetValueAction,
    onSaveMax: (value: number) => void,
  ) => {
    if (currentMaxValue === "") {
      dispatch(setMaxValueAction("Max"));
      onSaveMax(0);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    // Only close if clicking the overlay background itself
    if (event.target === refFilterModal.current?.parentElement) {
      handleDeactivateModal(event);
    }
  };

  React.useEffect(() => {
    dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
    dispatch(filterAppsActions.resetPageFilterSidebar());

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const elementLength = filterAppDataSorted.length;

  // React.useEffect(() => {
  //   if (appDataAll) {
  //     dispatch(
  //       filterAppsDataActions.setFilterAppDataSortedAll(appDataAll.results),
  //     );
  //   }
  // }, [appDataAll]);

  React.useEffect(() => {
    // paginate right side app list
    if (inView && isNext && !isFetching && Date.now() - lastFetchedTime > 400) {
      setLastFetchedTime(Date.now());
      dispatch(filterAppsActions.countPageFilter());
    }
  }, [inView, isFetching, lastFetchedTime]);

  const debouncedChangeInput = React.useCallback(
    debounce((value: string) => {
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsActions.resetPageFilter());

      dispatch(filterInputSearchActions.setAppName(value));
      dispatch(filterInputSearchActions.activeNameSearch());

      if (!isActiveContain) {
        dispatch(counterActions.addToArray("Contains Word"));
        dispatch(filterAppsActions.activeContain());
      }

      if (isActiveContain && value === "") {
        dispatch(counterActions.removeFromArray("Contains Word"));
        dispatch(filterAppsActions.deactiveContain());
      }
    }, 800),
    [dispatch, isActiveContain],
  );

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAppNameInput(value);
    debouncedChangeInput(value);
  };

  const handleResetInput = () => {
    if (appName !== "" && !isFetchingFilter) {
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterAppsActions.resetPageFilter());
      dispatch(filterAppsActions.resetPageFilterSidebar());
      dispatch(filterInputSearchActions.setAppName(""));
    }

    setAppNameInput("");

    if (isActiveContain) {
      dispatch(counterActions.removeFromArray("Contains Word"));
      dispatch(filterAppsActions.deactiveContain());
    }
  };

  const handleCloseAppName = () => {
    if (appName !== "") {
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterInputSearchActions.setAppName(""));
    }
  };

  const handleCloseCategories = () => {
    if (categories !== "" || dataCategories.length > 0) {
      dispatch(filterAppsDataActions.resetFilterAppDataSorted());
      dispatch(filterAppsDataActions.resetFilterAppData());
      dispatch(filterActions.deactivateCategory());
      dispatch(filterAppsActions.setCategories(""));
      setDataCategories([]);
    }
  };

  const resetSelectedFilters = () => {
    if (!isFetchingFilter) {
      setSelectedFilters({
        appName: "",
        categories: "",
        maxInstalls: 0,
        minInstalls: 0,
        maxRevenue: 0,
        minRevenue: 0,
        oscgte: 0,
        osclte: 0,
        paywallType: "",
        containsWordFirst: "",
        labelled: "",
        releaseDate: "",
        updateDate: "",
      });
      if (
        appName !== "" ||
        categories !== "" ||
        paywall !== "" ||
        oscgte !== "Min" ||
        osclte !== "Max" ||
        osclteNumber !== "" ||
        oscgteNumber !== "" ||
        downloadgte !== "Min" ||
        downloadgteNumber !== "" ||
        downloadlte !== "Max" ||
        downloadlteNumber !== "" ||
        revInstallRateGte !== "Min" ||
        revInstallRateLte !== "Max" ||
        revInstallRateLteNumber !== "" ||
        revInstallRateGteNumber !== "" ||
        revenuegte !== "Min" ||
        revenuelte !== "Max" ||
        revenuelteNumber !== "" ||
        revenuegteNumber !== "" ||
        indexSort !== 0 ||
        filterGroup !== "" ||
        isActiveCategories ||
        isActiveContain ||
        selectedDataMax !== "Max" ||
        selectedDataMin !== "Min" ||
        selectedDataRangeMax !== "Max" ||
        selectedDataRangeMin !== "Min" ||
        selectedYearMaxRelease !== null ||
        selectedYearMinRelease !== null ||
        selectedYearMaxUpdate !== null ||
        selectedYearMinUpdate !== null ||
        releasedFrom !== "" ||
        releasedTo !== "" ||
        updatedFrom !== "" ||
        updatedTo !== "" ||
        isActiveInstalls ||
        isActiveOnboarding ||
        isActivePaywall ||
        isActiveRevenue ||
        isActiveSort ||
        isActiveGroup ||
        avsTags !== "" ||
        only !== "not_hidden" ||
        activeButtonHidStar !== null ||
        appNameInput !== "" ||
        dataCategories.length > 0
      ) {
        dispatch(sortByCategoriesActions.setSortBy("None"));
        dispatch(sortByCategoriesActions.setGroup("None"));
        dispatch(sortByCategoriesActions.setIndexGroup(0));
        dispatch(filterAppsActions.setPaywall(""));
        dispatch(filterAppsActions.resetOscGte());
        dispatch(filterAppsActions.resetOscLte());
        dispatch(filterAppsActions.setCategories(""));
        setDataCategories([]);
        setSortDirection("desc");
        setAppNameInput("");
        dispatch(filterActions.deactivateCategory());
        dispatch(filterAppsActions.resetRevenueGte());
        dispatch(filterAppsActions.resetRevenueLte());
        dispatch(filterAppsActions.resetDownloadGte());
        dispatch(filterAppsActions.resetDownloadLte());
        dispatch(filterAppsActions.resetRevInstallRateGte());
        dispatch(filterAppsActions.resetRevInstallRateLte());
        dispatch(filterAppsActions.setFilterSort("revenue"));
        dispatch(sortByCategoriesActions.setIndexSort(4)); // 4 is revenue
        dispatch(filterAppsActions.setFilterGroup(""));
        dispatch(filterAppsActions.setIsPaywallOnly(false));

        dispatch(filterAppsActions.deactiveCategories());
        dispatch(filterAppsActions.deactivePaywall());
        dispatch(filterAppsActions.deactiveOnboarding());
        dispatch(filterAppsActions.deactiveInstalls());
        dispatch(filterAppsActions.deactiveRevenue());
        dispatch(filterAppsActions.deactiveContain());
        dispatch(filterAppsActions.setActiveModalIndexCategories([]));
        dispatch(filterAppsActions.setActiveModalIndexPaywall(null));
        dispatch(filterAppsActions.deactiveMagic());
        dispatch(filterAppsActions.deactiveLabelled());
        dispatch(filterAppsActions.setAvsTags(""));
        dispatch(filterAppsActions.setButtonRevenue(null));
        dispatch(filterAppsActions.setButtonInstalls(null));
        dispatch(filterAppsActions.setButtonOnboarding(null));
        dispatch(filterAppsActions.setOnly("not_hidden"));
        setActiveButtonHidStar(null);
        dispatch(filterAppsActions.setSelectedDataMax("Max"));
        dispatch(filterAppsActions.setSelectedDataMin("Min"));
        dispatch(filterAppsActions.setSelectedDataRangeMax("Max"));
        dispatch(filterAppsActions.setSelectedDataRangeMin("Min"));
        dispatch(filterAppsActions.deactiveUpdateDate());
        dispatch(filterAppsActions.deactiveReleaseDate());
        dispatch(filterAppsActions.setReleasedFrom(""));
        dispatch(filterAppsActions.setReleasedTo(""));
        dispatch(filterAppsActions.setUpdatedFrom(""));
        dispatch(filterAppsActions.setUpdatedTo(""));
        dispatch(counterActions.clearArray());
        dispatch(filterAppsDataActions.resetFilterAppDataSorted());
        dispatch(filterAppsDataActions.resetFilterAppData());
        dispatch(filterAppsDataActions.resetFilterAppDataSortedAll());
        dispatch(filterAppsActions.resetPageFilter());
        dispatch(filterAppsActions.resetPageFilterSidebar());
        setSelectedYearMinRelease(null);
        setSelectedYearMaxRelease(null);
        setSelectedYearMinUpdate(null);
        setSelectedYearMaxUpdate(null);
      }
      dispatch(filterAppsActions.setActiveButtonReleaseDate(null));
      dispatch(filterAppsActions.setActiveButtonUpdateDate(null));
      dispatch(modalFiltersActions.setIsReleaseDateOpen(false));
      dispatch(modalFiltersActions.setIsUpdateDateOpen(false));
      dispatch(sortByCategoriesActions.setIsActiveGroup(false));
      dispatch(sortByCategoriesActions.setIsActiveSort(false));
      dispatch(inputSearchActions.deactiveOCR());
      dispatch(counterActions.setCount(0));
      const currentDate = new Date();
      setMonthMin(currentDate);
      setMonthMax(currentDate);
      setMonthMinUpdate(currentDate);
      setMonthMaxUpdate(currentDate);

      if (!isFetchingFilter) {
        handleCloseCategories();
        handleCloseAppName();
      }
    }
    setActiveFlowType(null);
    dispatch(filterAppsActions.resetFlowsFilter());
  };

  const handleSaveFilter = (field: string, value: string | number) => {
    setSelectedFilters({ ...selectedFilters, [field]: value });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { charCode } = event;
    const charStr = String.fromCharCode(charCode);
    if (!/^[a-zA-Z]+$/.test(charStr)) {
      event.preventDefault();
    }
  };

  interface ExtraProps {
    value?: string | number | undefined;
    dataPaywall?: AppPaywall[];
    appDataCategories?: ICategoriesData | undefined;
    dataCategories?: string[] | undefined;
    appDataTags?: ResponseAvsTags | undefined;
    activeType?: string;
    setActiveType?: (state: string) => void;
    selectedYearMinRelease?: number | null;
    selectedYearMaxRelease?: number | null;
    selectedYearMinUpdate?: number | null;
    selectedYearMaxUpdate?: number | null;
    setSelectedYearMinRelease?: (value: number | null) => void;
    setSelectedYearMaxRelease?: (value: number | null) => void;
    setSelectedYearMinUpdate?: (value: number | null) => void;
    setSelectedYearMaxUpdate?: (value: number | null) => void;
    monthMin?: Date;
    monthMax?: Date;
    setMonthMin?: (value: Date) => void;
    setMonthMax?: (value: Date) => void;
    setDataCategories?: (dataCategories: string[]) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleReset?: () => void;
    onSaveMin?: (value: number) => void;
    onSaveMax?: (value: number) => void;
    handleMinBlur?: (
      value: string | number,
      setMinValueAction: SetValueAction,
      onSave: (value: number) => void,
    ) => void;
    handleMaxBlur?: (
      value: string | number,
      setMaxValueAction: SetValueAction,
      onSave: (value: number) => void,
    ) => void;
    isFetchingFilter?: boolean;
    selectedCategoryIds?: number[];
    setSelectedCategoryIds?: (value: number[]) => void;
    activeButton?: "hidden" | "starred" | null;
    setActiveButton?: (state: "hidden" | "starred" | null) => void;
  }

  interface CommonProps {
    value?: string | number | undefined;
    dataPaywall: AppPaywall[] | undefined;
    appDataCategories?: ICategoriesData;
    dataCategories: string[];
    appDataTags: ResponseAvsTags;
    activeType: string;
    setActiveType: (state: string) => void;
    selectedYearMinRelease: number | null;
    selectedYearMaxRelease: number | null;
    selectedYearMinUpdate: number | null;
    selectedYearMaxUpdate: number | null;
    setSelectedYearMinRelease: (value: number | null) => void;
    setSelectedYearMaxRelease: (value: number | null) => void;
    setSelectedYearMinUpdate: (value: number | null) => void;
    setSelectedYearMaxUpdate: (value: number | null) => void;
    monthMin: Date;
    monthMax: Date;
    setMonthMin: (value: Date) => void;
    setMonthMax: (value: Date) => void;
    setDataCategories: (dataCategories: string[]) => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleReset: () => void;
    onSaveMin: (value: number) => void;
    onSaveMax: (value: number) => void;
    handleMinBlur: (
      value: string | number,
      setMinValueAction: SetValueAction,
      onSave: (value: number) => void,
    ) => void;
    handleMaxBlur: (
      value: string | number,
      setMaxValueAction: SetValueAction,
      onSave: (value: number) => void,
    ) => void;
    isFetchingFilter: boolean;
    onSave: (value: string) => void;
    selectedCategoryIds: number[];
    setSelectedCategoryIds: (value: number[]) => void;
    activeButton: "hidden" | "starred" | null;
    setActiveButton: (state: "hidden" | "starred" | null) => void;
  }

  type ComponentProps = CommonProps & {
    onSave?: FilterHandler;
  } & Partial<
    ContainsWordsProps &
    PaywallTypeProps &
    OnboardingStepsProps &
    MontlyRevenueProps &
    MontlyInstallsProps &
    RevInstallRateProps &
    ICategoryModalProps &
    LabelledModalProps &
    ReleaseDateModalProps &
    UpdateDateModalProps
  >;

  const modals: {
    isOpen: boolean;
    Component: React.ComponentType<ComponentProps>;
    onSave?: FilterHandler;
    onSaveMin?: FilterHandler;
    onSaveMax?: FilterHandler;
    extraProps?: ExtraProps;
  }[] = [
      {
        isOpen: isContainsWords,
        Component: ContainsWords,
        onSave: (value: string) => handleSaveFilter("appName", value),
        extraProps: {
          value: appName,
          onChange: handleChangeInput,
          handleKeyPress,
          handleReset: handleResetInput,
          isFetchingFilter,
        },
      },
      {
        isOpen: isPaywallOpen,
        Component: PaywallType,
        onSave: (value: string) => handleSaveFilter("paywallType", value),
        extraProps: {
          dataPaywall: appDataPaywall,
          isFetchingFilter,
          rootActiveView,
          activeButton,
          setActiveButton,
        },
      },
      {
        isOpen: isOnboardingStepsOpen,
        Component: OnboardingSteps,
        onSaveMin: (value: number) => handleSaveFilter("oscgte", value),
        onSaveMax: (value: number) => handleSaveFilter("osclte", value),
        extraProps: {
          handleMinBlur,
          handleMaxBlur,
          isFetchingFilter,
          rootActiveView
        },
      },
      {
        isOpen: isMontlyRevenueOpen,
        Component: MontlyRevenue,
        onSaveMin: (value: number) => handleSaveFilter("minRevenue", value),
        onSaveMax: (value: number) => handleSaveFilter("maxRevenue", value),
        extraProps: {
          handleMinBlur,
          handleMaxBlur,
          isFetchingFilter,
          rootActiveView
        },
      },
      {
        isOpen: isMontlyInstallsOpen,
        Component: MontlyInstalls,
        onSaveMin: (value: number) => handleSaveFilter("minInstalls", value),
        onSaveMax: (value: number) => handleSaveFilter("maxInstalls", value),
        extraProps: {
          handleMinBlur,
          handleMaxBlur,
          isFetchingFilter,
          rootActiveView
        },
      },
      {
        isOpen: isRevInstallRateOpen,
        Component: RevInstallRate,
        onSaveMin: (value: number) => handleSaveFilter("minInstalls", value),
        onSaveMax: (value: number) => handleSaveFilter("maxInstalls", value),
        extraProps: {
          handleMinBlur,
          handleMaxBlur,
          isFetchingFilter,
          rootActiveView
        },
      },
      {
        isOpen: isCategoryOpen,
        Component: CategoryModal,
        onSave: (value: string) => handleSaveFilter("categories", value),
        extraProps: {
          appDataCategories,
          isFetchingFilter,
          dataCategories,
          setDataCategories,
          selectedCategoryIds,
          setSelectedCategoryIds,
          rootActiveView
        },
      },
      // {
      //   isOpen: isLabelledOpen,
      //   Component: LabelledModal,
      //   onSave: (value: string) => handleSaveFilter("labelled", value),
      //   extraProps: {
      //     appDataTags,
      //     activeType,
      //     setActiveType,
      //   },
      // },
      {
        isOpen: isReleaseDateOpen,
        Component: ReleaseDateModal,
        onSave: (value: string) => handleSaveFilter("releaseDate", value),
        extraProps: {
          selectedYearMinRelease,
          setSelectedYearMinRelease,
          selectedYearMaxRelease,
          setSelectedYearMaxRelease,
          monthMin,
          setMonthMin,
          monthMax,
          setMonthMax,
          rootActiveView
        },
      },
      {
        isOpen: isUpdateDateOpen,
        Component: UpdateDateModal,
        onSave: (value: string) => handleSaveFilter("updateDate", value),
        extraProps: {
          selectedYearMinUpdate,
          setSelectedYearMinUpdate,
          selectedYearMaxUpdate,
          setSelectedYearMaxUpdate,
          monthMin: monthMinUpdate,
          setMonthMin: setMonthMinUpdate,
          monthMax: monthMaxUpdate,
          setMonthMax: setMonthMaxUpdate,
          rootActiveView
        },
      },
      {
        isOpen: isHiddenOpen,
        Component: HiddenModal,
        extraProps: {
          activeButton,
          setActiveButton,
        },
      },
      {
        isOpen: isFlowsOpen,
        Component: FlowsTypes,
        extraProps: {
          activeButton: activeFlowType,
          setActiveButton: setActiveFlowType,
        },
      },
    ];

  const isAnyFilterActive =
    appName !== "" ||
    categories !== "" ||
    paywall !== "" ||
    oscgte !== "Min" ||
    osclte !== "Max" ||
    osclteNumber !== "" ||
    oscgteNumber !== "" ||
    downloadgte !== "Min" ||
    downloadgteNumber !== "" ||
    downloadlte !== "Max" ||
    downloadlteNumber !== "" ||
    revenuegte !== "Min" ||
    revenuelte !== "Max" ||
    revenuelteNumber !== "" ||
    revenuegteNumber !== "" ||
    indexSort !== 0 ||
    filterGroup !== "" ||
    isActiveCategories ||
    isActiveContain ||
    selectedDataMax !== "Max" ||
    selectedDataMin !== "Min" ||
    selectedDataRangeMax !== "Max" ||
    selectedDataRangeMin !== "Min" ||
    selectedYearMaxRelease !== null ||
    selectedYearMinRelease !== null ||
    selectedYearMaxUpdate !== null ||
    selectedYearMinUpdate !== null ||
    releasedFrom !== "" ||
    releasedTo !== "" ||
    updatedFrom !== "" ||
    updatedTo !== "" ||
    isActiveInstalls ||
    isActiveOnboarding ||
    isActivePaywall ||
    isActiveRevenue ||
    isActiveSort ||
    isActiveGroup ||
    avsTags !== "" ||
    only !== "not_hidden" ||
    activeButtonHidStar !== null ||
    appNameInput !== "" ||
    dataCategories.length > 0 ||
    activeFlowType !== null;

  // Add click handler for the entire sidebar
  const handleSidebarClick = (e: React.MouseEvent) => {
    // Close revenue modal if it's open
    if (revenuaOpen) {
      // Don't close if clicking the revenue modal itself or the trigger button
      if (
        sortByRef.current?.contains(e.target as Node) ||
        (e.target as HTMLElement).closest('.categoriesRevenue')
      ) {
        return;
      }
      dispatch(sortByCategoriesActions.closeRevenua());
    }
  };


  return (
    <WrapperModal>
      <div
        className={`${styles.root} ${sortMenuOpen ? styles.sortMenuOpen : ''}`}
        ref={refFilterModal}
        onMouseEnter={() => setVisibleModal(true)}
        onMouseLeave={() => setVisibleModal(false)}
        onClick={handleSidebarClick}
      >

        <div className={styles.categories}>
          <div className={styles.categoriesLeft}>
            {/* <div
              className={styles.categoriesRevenue}
              onClick={() =>
                dispatch(sortByCategoriesActions.toggleRevenuaOpen())
              }
            > */}
            {/* <SortByCategories
                text="Sort by"
                textSpan={sortBy}
              /> */}
            {/* </div> */}
            <div
              className={styles.categoriesDeveloper}
              onClick={() =>
                dispatch(sortByCategoriesActions.toggleDeveloperOpen())
              }
            >
              {/* <SortByCategories text="Group by" textSpan={group} /> */}
            </div>
          </div>
          <div>{/* <p>Showing {dataCount} apps</p> */}</div>

        </div>
        <div className={styles.inputContainer}>
          {/* {(isActiveInput || isActiveOCR) && (
            <div
              onClick={() => {
                dispatch(inputSearchActions.deactiveInputSearch());
                dispatch(inputSearchActions.deactiveOCR());
              }}
              className={styles.arrowIcon}
            >
              <img src="img/arrowFilterModal.svg" alt="arrowFilterModal" />
            </div>
          )} */}

          <div className={styles.inputs}>
            {/* <MagicOCR
              magicOcrValue={magicOcrValue}
              handleChangeOCR={handleChangeOCR}
              handleDeactivateModal={handleDeactivateModal}
              setVisibleModal={setVisibleModal}
            /> */}
            <SortByButton setSortMenuOpen={setSortMenuOpen} />
            {rootActiveView !== ACTIVE_VIEW_SEARCH && (
              <InputSearch
                value={appNameInput}
                onChange={(e) => handleChangeInput(e)}
                ref={inputRef}
                isAnyFilterActive={isAnyFilterActive}
                resetSelectedFilters={resetSelectedFilters}
              />
            )}
          </div>


        </div>
        <div className={styles.mainContainer}>
          <div className={styles.sortMenu}>
            <FilterModalItem
              filterAppDataSortedAll={uniqueFilterAppDataSorted}
              selectedFilters={selectedFilters}
              dataModal={data}
              isFetchingFilter={isFetchingFilter}
              showContainsWordFilter={appName === ""}
              dataCategories={dataCategories}
              modals={modals}
              refetch={refetch}
              isNext={isNext}
              rootActiveView={rootActiveView}
            />
          {isRootPath && (
            <div>
              <p>
                {activeView == 1 && "Single View"}
                {activeView == 0 && "Lateral View"}
                <Switch checked={activeView == 1} onChange={() => { dispatch(buttonFilterActions.toggleActiveView()) }} />
              </p>
            </div>
          )}
          </div>
          <div className={styles.contentModal}>
            {modals.map(
              (
                { isOpen, Component, onSave, onSaveMin, onSaveMax, extraProps },
                index,
              ) =>
                isOpen && (
                  <div
                    key={index}
                    className={styles[Component.name.toLowerCase()]}
                  >
                    <Component
                      onSave={onSave as ComponentProps["onSave"]}
                      onSaveMax={onSaveMax as ComponentProps["onSaveMax"]}
                      onSaveMin={onSaveMin as ComponentProps["onSaveMin"]}
                      {...extraProps}
                    />
                  </div>
                ),
            )}
          </div>

          {modals.every(({ isOpen }) => !isOpen) && (rootActiveView === ACTIVE_VIEW_HOME) && (

            <div className={styles.filterSearch}>
              <h1 className={styles.filterSearchTitle}>All matching apps: {dataCount}</h1>
              <FilterSortedElements
                filterAppDataSortedAll={uniqueFilterAppDataSorted}
                isNext={isNext}
                elementLength={elementLength}
                ref={ref}
                isError={isError}
              />
            </div>
          )}

        </div>
        {/* {isActiveOCR && <UploadImage />} */}
        {revenuaOpen && <CategoriesRevenueModal ref={sortByRef} />}
        {developerOpen && <CategoriesDeveloperModal />}
      </div>
    </WrapperModal>
  );
};

export default FilterModalsidebar;