export const dataHidStarMenu = [
  {
    text: "Hidden Apps",
    target: "/saved-library/hidden/",
    img: "/img/HideStarPage/HiddenGrey.svg",
    imgActive: "/img/HideStarPage/HiddenBlack.svg",
  },
  {
    text: "Starred Apps",
    target: "/saved-library/starred/",
    img: "/img/HideStarPage/StarGrey.svg",
    imgActive: "/img/HideStarPage/StarBlack.svg",
  },
];
