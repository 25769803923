// styles

import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./ModalSaveModeMenu.module.scss";

interface ModalSaveModeMenuProps {
  activeIndex: number;
  handleItemClick: (index: number) => void;
}

const dataMenu = [
  { text: "Screens", target: ["/saved-library/video/"] },
  { text: "Store Screenshots", target: ["/saved-library/store/"] },
  { text: "Animation", target: ["/saved-library/animation/"] },
  { text: "Web Onboarding", target: ["/saved-library/onboarding/"] },
 
];

const SavedModeMenu: React.FC<ModalSaveModeMenuProps> = ({
  activeIndex,
  handleItemClick,
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 750);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const currentPath = window.location.pathname;

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 750);
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest(".dropdownButton")
      ) {
        setIsDropdownOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getMenuText = () => {
    if (currentPath.includes("/saved-library/video")) return "Screens";
    if (currentPath.includes("/saved-library/store"))
      return isSmallScreen ? "Store Screens" : "Store Screenshots";
    if (currentPath.includes("/saved-library/animation")) return "Animation";
    if (currentPath.includes("/saved-library/onboarding")) return "Onboarding";
    
    return "Select Category";
  };

  const isMenuItemActive = (targets: string[]) => {
    return targets.some((target) => currentPath.includes(target));
  };

  const handleMenuItemClick = (index: number, targets: string[]) => {
    handleItemClick(index);
    navigate(targets[0]);
    setIsDropdownOpen(false);
  };

  return (
    <div className={styles.root} ref={dropdownRef}>
      <div className={styles.dropdownContainer}>
        <button
          className={`${styles.dropdownButton} dropdownButton`}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <span>{getMenuText()}</span>
          <img
            src="/img/chevron_icon_new.svg"
            alt={isDropdownOpen ? "Close" : "Open"}
            style={{
              transform: isDropdownOpen ? "rotate(-90deg)" : "rotate(90deg)",
            }}
            className={`${styles.arrowIcon} ${isDropdownOpen ? styles.open : ""}`}
          />
        </button>

        {isDropdownOpen && (
          <div className={styles.dropdownMenu}>
            {dataMenu.map((item, index) => {
              const displayText =
                item.text === " Store Screenshots" && isSmallScreen
                  ? "Store Screens"
                  : item.text;

              return (
                <div
                  key={index}
                  className={`${styles.menuItem} ${isMenuItemActive(item.target) ? styles.active : ""}`}
                  onClick={() => handleMenuItemClick(index, item.target)}
                >
                  <span>{displayText}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export { SavedModeMenu };
