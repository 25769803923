import React from "react";
import styles from "./SavedModeTabs.module.scss";
import { useLocation, useNavigate } from "react-router-dom";

interface Route {
  path: string;
  label: string;
  icons: {
    active: string;
    default: string;
  };
  childRoutes?: string[];
}

const routes: Route[] = [
  {
    path: "/saved-library/video/",
    label: "Screens",
    icons: {
      active: "/img/smart-phone-gradient.svg",
      default: "/img/smart-phone-grey.svg",
    },
    childRoutes: [
      "/saved-library/video/",
      "/saved-library/store/",
      "/saved-library/animation/",
      "/saved-library/onboarding/",
    ],
  },
  {
    path: "/saved-library/groups/",
    label: "Saved Groups",
    icons: {
      active: "/img/saved-groups-gradient.svg",
      default: "/img/saved-groups-grey.svg",
    },
    childRoutes: [],
  },
  {
    path: "/saved-library/hidden/",
    label: "Hidden & Starred Apps",
    icons: {
      active: "/img/star-gradient.svg",
      default: "/img/star-grey.svg",
    },
    childRoutes: ["/saved-library/hidden/", "/saved-library/starred/"],
  },
];

const SavedModeTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Helper function to check if a tab is active
  const isTabActive = (route: Route) => {
    if (route.childRoutes && route.childRoutes.length > 0) {
      return route.childRoutes.some((childRoute) =>
        location.pathname.includes(childRoute),
      );
    }
    return location.pathname === route.path;
  };

  return (
    <div className={styles.tabContainer}>
      {routes.map((route, index) => (
        <div
          key={index}
          className={`${styles.tabItem} ${
            isTabActive(route) ? styles.tabItemActive : ""
          }`}
          onClick={() => navigate(route.path)}
        >
          <img
            src={isTabActive(route) ? route.icons.active : route.icons.default}
            alt={route.label}
          />
          <p
            className={`${styles.tabLabel} ${
              isTabActive(route) ? styles.tabLabelActive : ""
            }`}
          >
            {route.label}
          </p>
        </div>
      ))}
      <div className={styles.tabBorderLine} />
    </div>
  );
};

export default SavedModeTabs;
