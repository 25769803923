import React from "react";
import { useDispatch } from "react-redux";
import { sortByCategoriesActions } from "@/entities/sortByCategories";
import styles from "./ModalSaveModeSortBy.module.scss";

const ModalSaveModeSortBy: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <div
      className={styles.root}
      onClick={() => dispatch(sortByCategoriesActions.toggleSortSaveMode())}
    >
      <div className={styles.sortBy}>
        <img
          src="/img/whiteIcon/Filter.svg"
          alt="buttonImg"
          loading="lazy"
          className={styles.sortByIcon}
        />
      </div>
    </div>
  );
};

export default ModalSaveModeSortBy;
