import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

import LoginPageWrapper from "@/processes/wrappers/LoginPageWrapper/LoginPageWrapper";
import Freetools from "@/processes/wrappers/FreeToolsWrapper/FreeToolsWrapper";

import {
  AppRootView,
  HomeRootView,
  SearchRootView,
  HiddenStarredRootView,
  HiddenStarredBaseView,
  HiddenAppsView,
  StarredAppsView,
  PolicyView,
  TermsView,
  CopyrightView,
  SavedModeRootView,
  SavedModeBaseView,
  SavedModeVideoView,
  SavedModeStoreView,
  SavedModeGroupListView,
  SavedModeGroupDetailView,
  AppVideoView,
  EmailVerifyView,
  OAuthCallbackView,
  JoinView,
  DeveloperDetailView,
  AboutUsView,
  RegisterView,
  ResetView,
  CheckoutDoneView,
} from "@/Views";
import { AccountTeamsRootView } from "@/Views/AccountTeamsView/AccountTeamsRootView";
import { AccountTeamsBaseView } from "@/Views/AccountTeamsView/AccountTeamsBaseView";
import { AccountView, TeamsView } from "@/Views/AccountTeamsView";
import SavedModeSharedView from "@/Views/SavedModeSharedView";
import FormEmailSend from "@/shared/ui/FormEmailSend/FormEmailSend";
import { NoContext } from "@/Views/SearchView2/NoContext/NoContext";
import AnimationExplorer from "@/pages/FreeContent/AnimationExplorer";
import ChangePassword from "@/pages/ChangePassword/ChangePassword";
import LandingMobile from "@/Views/LandingMobile/LandingMobile";
import { MeProvider } from "@/Hooks";
import { SavedModeAnimationView } from "@/Views/SavedModeView/SavedModeAnimationView";
import { SavedModeOnboardingView } from "@/Views/SavedModeView/SavedModeOnboardingView";
import WebOnboarding from "@/pages/WebOnboarding/WebOnboarding";
import { HomeNew } from "@/Views/Virtual/HomeNew";
import { HomeStore } from "@/Views/Virtual/HomeStore";
import Error from "@/pages/Error/Error";
import SwitchToDesktop from "@/shared/ui/SwitchToDesktop/SwitchToDesktop";

import NewRegister from "@/Views/NewRegister/index";

const Root = () => {
  return (
    <MeProvider>
      <Outlet />
    </MeProvider>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route element={<AppRootView />}>
        <Route element={<HomeRootView />}>
          <Route path="/" element={<HomeNew />} />
          <Route path="/store-screenshots/" element={<HomeStore />} />
          <Route path="/search/*" element={<SearchRootView />}>
            <Route path="" element={<NoContext />} />
          </Route>
          <Route path="/animation-explorer/" element={<AnimationExplorer />} />
          <Route path="/web-onboardings/" element={<WebOnboarding />} />
        </Route>
        <Route path="shared/:key" element={<SavedModeSharedView />} />
        <Route path="/settings/*" element={<AccountTeamsRootView />}>
          <Route element={<AccountTeamsBaseView />}>
            <Route path="account" element={<AccountView />} />
            <Route path="teams/:uuid/members" element={<TeamsView />} />
            {/* <Route path="teams/:uuid/billing" element={<TeamsView />} /> */}
          </Route>
          <Route path="" element={<Navigate to="account" />} />
          <Route path="*" element={<Navigate to="/settings/account" />} />
        </Route>

        <Route path="/apps/:slug/" element={<AppVideoView />} />
        <Route path="/developers/:slug/" element={<DeveloperDetailView />} />
        <Route path="/checkout/done/" element={<CheckoutDoneView />} />
        <Route path="/free-tools/" element={<Freetools />} />
        <Route path="/about-us/" element={<AboutUsView />} />

        <Route path="hidden-starred-app/*" element={<HiddenStarredRootView />}>
          <Route element={<HiddenStarredBaseView />}>
            <Route path="starred" element={<StarredAppsView />} />
            <Route path="hidden" element={<HiddenAppsView />} />
          </Route>
          <Route
            path=""
            element={<Navigate to="/hidden-starred-app/hidden/" />}
          />
          <Route
            path="*"
            element={<Navigate to="/hidden-starred-app/hidden/" />}
          />
        </Route>

        <Route path="saved-library/*" element={<SavedModeRootView />}>
          <Route element={<SavedModeBaseView />}>
            <Route path="video" element={<SavedModeVideoView />} />
            <Route path="store" element={<SavedModeStoreView />} />
            <Route path="animation" element={<SavedModeAnimationView />} />
            <Route path="onboarding" element={<SavedModeOnboardingView />} />
            <Route path="starred" element={<StarredAppsView />} />
            <Route path="hidden" element={<HiddenAppsView />} />
            <Route path="groups" element={<SavedModeGroupListView />} />
          </Route>
          <Route path="groups/:id/" element={<SavedModeGroupDetailView />} />
          <Route path="" element={<Navigate to="/saved-library/video/" />} />
          <Route path="*" element={<Navigate to="/saved-library/video/" />} />
        </Route>
      </Route>
      <Route path="/join/:key/" element={<JoinView />} />
      <Route path="/register/" element={<RegisterView />} />
      <Route path="/newregister/" element={<NewRegister />} />
      <Route path="/login" element={<LoginPageWrapper />} />
      <Route path="/reset" element={<ResetView />} />
      <Route path="/reset/code/:uid/:token/" element={<ChangePassword />} />
      <Route path="/verify/:token/" element={<EmailVerifyView />} />
      <Route path="/privacy/" element={<PolicyView />} />
      <Route path="/terms/" element={<TermsView />} />
      <Route path="/copyright/" element={<CopyrightView />} />
      <Route path="/verify-email/" element={<FormEmailSend />} />
      <Route path="/oauth/google/callback/" element={<OAuthCallbackView />} />
      <Route path="/landing-mobile/" element={<LandingMobile />} />
      <Route path="/error/" element={<Error />} />
      <Route path="/desktop/" element={<SwitchToDesktop />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Route>,
  ),
);

export default router;
